import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, tap } from 'rxjs';

export const authGuard: CanActivateFn = (_, state): Observable<boolean> => {
  const auth = inject(AuthService);
  const router = inject(Router);

  return auth.isAuthenticated$.pipe(
    tap(isLoggedIn => {
      if (!isLoggedIn) {
        sessionStorage.setItem('returnUrl', state.url);
        router.navigate(['/login']).then(
          () => {},
          () => {}
        );
      }
    })
  );
};

import { CanActivateFn, Router } from '@angular/router';
import { Observable, tap, combineLatest, map } from 'rxjs';
import { inject } from '@angular/core';
import { PaymentSubscriptionService } from '@shared/services';

export const paymentSubscriptionGuard: CanActivateFn = (
  route,
  _state
): Observable<boolean> => {
  const paymentSubscription = inject(PaymentSubscriptionService);
  const router = inject(Router);
  const shouldHaveSubscription = route.data[
    'shouldHaveSubscription'
  ] as boolean;

  return combineLatest([
    paymentSubscription.isPaymentPending$,
    paymentSubscription.isCanceled$,
    paymentSubscription.isRequested$,
  ]).pipe(
    tap(([isPaymentPending, isCanceled, isRequested]) => {
      if (
        shouldHaveSubscription &&
        (isCanceled || isRequested || isPaymentPending)
      ) {
        router.navigate(['/manage/subscription-signup']).then(
          () => {},
          () => {}
        );
      } else if (
        !shouldHaveSubscription &&
        !(isCanceled || isRequested || isPaymentPending)
      ) {
        router.navigate(['/manage/rewards']).then(
          () => {},
          () => {}
        );
      }
    }),
    map(([_, isCanceled, isRequested]) => {
      if (shouldHaveSubscription) {
        return !(isCanceled || isRequested);
      } else {
        return isCanceled || isRequested;
      }
    })
  );
};
